import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import ProfilePicture from "../common/ProfilePicture"
import { ReactComponent as CopyImg } from "../images/copy-count.svg"
import { ReactComponent as RegisterImg } from "../images/register-count.svg"
import { ReactComponent as CameraImg } from "../images/photo-change.svg"
import { ReactComponent as InfoImg } from "../images/info.svg"
import { ReactComponent as EditImg } from "../images/nick-edit.svg"
import { db, storage, logUserEvent } from "../common/firebase.utils"
import compression from "browser-image-compression"
import { getHighestValues, isLimited } from "../common/utils"
import { useUserInfo } from "../hooks/useUserInfo"
import { useDispatch } from "react-redux"
import { update } from "../store/slices/userSlice"
import moment from "moment"
import { UNLIMITED_DATE_FORMAT } from "../common/constants"
import WithdrawPopup from "./WithdrawPopup"
import MoreMenus from "../common/MoreMenus"
import MembershipBar from "./MembershipBar"
import useIsMobile from "../hooks/useIsMobile"
import NicknamePopup from "./NicknamePopup"

const MAX_PHOTO_SIZE_MB = 1

function Profile() {
  const { userId, photo, nickname, unlimitedUntil, membershipFrom } =
    useUserInfo()
  const [photoUploading, setPhotoUploading] = useState(false)
  const [showWithdrawPopup, setShowWithdrawPopup] = useState(false)
  const [showNicknamePopup, setShowNicknamePopup] = useState(false)
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const onChangePhoto = async ({ target }) => {
    if (photoUploading) {
      return
    }
    const file = target.files[0]
    if (!file) {
      return
    }
    if (file.size > MAX_PHOTO_SIZE_MB * 1024 * 1024) {
      alert(`파일 크기가 ${MAX_PHOTO_SIZE_MB}MB를 넘습니다.`)
      return
    }
    setPhotoUploading(true)

    const compressedFile = await compressImage(file)
    const ref = storage.ref().child(`profile/${userId}`)
    ref
      .put(compressedFile)
      .then(snapshot => {
        snapshot.ref.getDownloadURL().then(url => {
          db.collection("users")
            .doc(userId)
            .update({
              photo: url
            })
            .then(() => {
              dispatch(
                update({
                  photo: url
                })
              )
              setPhotoUploading(false)
            })
            .catch(e => {
              console.error(e)
              setPhotoUploading(false)
            })
        })
      })
      .catch(() => setPhotoUploading(false))
  }

  const compressImage = async image => {
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 256
      }
      return await compression(image, options)
    } catch (e) {
      console.log(e)
    }
  }

  const onClickNickname = () => {
    setShowNicknamePopup(true)
  }

  const onClickWithdraw = () => {
    setShowWithdrawPopup(true)
  }

  return (
    <Container>
      {!isMobile && membershipFrom == null && <MembershipBar userId={userId} />}
      <MoreMenus className="more-menu" dotsSize={16}>
        <div>
          <WithdrawButton onClick={onClickWithdraw}>계정삭제</WithdrawButton>
        </div>
      </MoreMenus>
      <PhotoAndNick>
        <label htmlFor="photo-input">
          <ProfilePicture size="100px" src={photo} />
          <CameraImg />
          <input
            id="photo-input"
            type="file"
            onChange={onChangePhoto}
            accept="image/png, image/jpeg"
            disabled={photoUploading}
          />
        </label>
        <p id="nick" onClick={onClickNickname}>
          <b className="truncate overflow-hidden max-w-[104px]">{nickname}</b>
          님
          <EditImg className="ml-1" />
        </p>
      </PhotoAndNick>
      {membershipFrom == null ? (
        <CopyableInfo unlimitedUntil={unlimitedUntil} />
      ) : (
        <MembershipBar
          userId={userId}
          membershipFrom={membershipFrom}
          className="mx-6 mb-6"
        />
      )}
      <Situations userId={userId} />
      <Counts userId={userId} />
      {showNicknamePopup && (
        <NicknamePopup
          userId={userId}
          currentNickname={nickname}
          onCloseClick={() => setShowNicknamePopup(false)}
        />
      )}
      {showWithdrawPopup && (
        <WithdrawPopup onCloseClick={() => setShowWithdrawPopup(false)} />
      )}
    </Container>
  )
}

function CopyableInfo({ unlimitedUntil }) {
  const [showTooltip, setShowTooltip] = useState(false)
  const history = useHistory()

  const getFormatedDate = () =>
    moment(unlimitedUntil, UNLIMITED_DATE_FORMAT).format("YYYY년 M월 D일")
  const limited = isLimited(unlimitedUntil)
  const goToWrite = () => {
    history.push("/write")
  }

  return (
    <CopyableInfoContainer
      onMouseOver={() => setShowTooltip(true)}
      onMouseOut={() => setShowTooltip(false)}
      onClick={limited ? goToWrite : null}
    >
      {!(limited && showTooltip) && <InfoImg />}
      {limited ? (
        showTooltip ? (
          "템플릿 등록하러 가기"
        ) : (
          "템플릿을 등록하여 이용 제한을 해제하세요"
        )
      ) : (
        <p>
          <b>{getFormatedDate()}까지</b>무제한 이용 가능
        </p>
      )}
      {showTooltip && (
        <div className="tooltip">
          가장 마지막 템플릿 등록일로부터 30일간 뭐라고할까의{" "}
          <b>모든 템플릿에 대해 무제한 조회 및 복사</b>하실 수 있습니다. 템플릿
          공유에 참여해주셔서 감사합니다.
        </div>
      )}
    </CopyableInfoContainer>
  )
}

function Situations({ userId }) {
  const MAX_DISPLAY_COUNT = 3
  const situationCounts = useRef({})
  const [favSituations, setFavSituations] = useState([])

  const history = useHistory()

  useEffect(() => {
    if (userId) {
      db.collection("users")
        .doc(userId)
        .get()
        .then(doc => {
          const counts = doc.data().situationCopyCount
          situationCounts.current = counts

          const _favSituations = []
          if (counts) {
            const favs = getHighestValues(counts, MAX_DISPLAY_COUNT)
            const favCount = Object.keys(favs).length
            let i = 0
            for (const situId in favs) {
              db.collection("situations")
                .doc(situId)
                .get()
                .then(situDoc => {
                  if (situDoc.exists) {
                    _favSituations.push(situDoc.data().title)
                  }
                  i++
                  if (i === favCount) {
                    setFavSituations(_favSituations)
                  }
                })
            }
          } else {
            setFavSituations([])
          }
        })
    }
  }, [userId])

  if (!favSituations.length) {
    return null
  }

  const onClickSituation = situation => {
    history.push(`/templates?situation=${situation}`)
    logUserEvent("click_fav_situation")
  }

  return (
    <SituationsContainer>
      <p>이런 템플릿을 자주 복사하셨네요!</p>
      <div className="no-scrollbar">
        {favSituations.map((situation, i) => (
          <span key={i} onClick={() => onClickSituation(situation)}>
            {situation}
          </span>
        ))}
      </div>
    </SituationsContainer>
  )
}

function Counts({ userId }) {
  const DEFAULT_COUNTS = { copy: "-", template: "-" }
  const [counts, setCounts] = useState(DEFAULT_COUNTS)

  useEffect(() => {
    if (userId) {
      db.collection("users")
        .doc(userId)
        .get()
        .then(doc => {
          const user = doc.data()
          setCounts({
            copy: user.copyCount || "-",
            template: user.templateCount || "-"
          })
        })
        .catch(e => {
          console.error(e)
          setCounts(DEFAULT_COUNTS)
        })
    }
  }, [userId])

  return (
    <CountsContainer>
      <div>
        <CopyImg />총 복사 횟수
        <span>{counts.copy}</span>
      </div>
      <div>
        <RegisterImg />
        등록한 템플릿 수<span>{counts.template}</span>
      </div>
    </CountsContainer>
  )
}

const Container = styled.div`
  width: 310px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
  .more-menu {
    position: absolute;
    right: 8px;
    bottom: 390px;
  }
  @media (max-width: 800px) {
    width: 100%;
    border: none;
    margin-bottom: 0;
  }
`

const PhotoAndNick = styled.div`
  padding: 24.5px 40px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  #photo-input {
    display: none;
  }
  label {
    position: relative;
    margin-right: 24px;
    cursor: pointer;
    svg {
      position: absolute;
      right: -2px;
      bottom: 0;
    }
  }
  p {
    cursor: pointer;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    b {
      font-weight: 700;
    }
  }
  @media (max-width: 800px) {
    justify-content: center;
    padding: 17px 24px 24px;
  }
`

const CopyableInfoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  margin: 0 24px;
  margin-bottom: 24px;
  background: #5551ff;
  border-radius: 32px;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
  b {
    font-weight: 600;
    margin-right: 3px;
  }
  .tooltip {
    position: absolute;
    padding: 12px 16px;
    width: 252px;
    background: #333333;
    border-radius: 4px;
    bottom: 48px;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    ::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid #333333;
    }
  }
  @media (max-width: 800px) {
    margin: 0 40px 24px;
  }
`

const SituationsContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
  > div {
    max-width: 100%;
    padding: 0 24.5px;
    display: inline-block;
    margin-top: 9px;
    overflow-x: scroll;
    scroll-behavior: revert;
    white-space: nowrap;
    > span {
      display: inline-block;
      margin-right: 8px;
      line-height: 28px;
      padding: 0 12px;
      background: rgba(85, 81, 255, 0.05);
      border: 1px solid #5551ff;
      border-radius: 15px;
      font-size: 12px;
      color: #5551ff;
      cursor: pointer;
      :last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 800px) {
      padding: 0 31px;
      -webkit-overflow-scrolling: touch;
    }
  }
`

const CountsContainer = styled.div`
  padding: 24px;
  background: #f8f8f8;
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 12.5px;
    font-size: 16px;
    svg {
      margin-right: 9px;
    }
    span {
      display: inline-block;
      flex: 1;
      text-align: right;
      font-weight: 600;
      font-size: 24px;
      color: #333333;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 800px) {
    padding: 17.5px;
    > div {
      padding: 0 56px;
      margin-bottom: 13.5px;
      svg {
        width: 32px;
        height: 32px;
      }
      font-size: 14px;
      span {
        font-size: 16px;
      }
    }
  }
`

const WithdrawButton = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: #d5d5d5;
  margin-top: 4px;
`

export default Profile
