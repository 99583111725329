import { MIN_CONTENT_LENGTH } from "../community/Write"

export const UNLIMITED_DATE = 30
export const UNLIMITED_DATE_FORMAT = "YYYY-MM-DD"
export const PUBLIC_LIMIT_COUNT_ALL = 9
export const PUBLIC_LIMIT_COUNT_SITUATION = 3

export const MIN_TEMPLATE_LENGTH = 15
export const MAX_TEMPLATE_LENGTH = 1500
export const MAX_COMMENT_LENGTH = 500

export const REPORT_HIDE_COUNT = 3
export const REPORT_HIDE_COUNT_TEMPLATE = 2

export const URL = {
  TERMS_OF_SERVICE:
    "https://valoy.notion.site/f87b68cf063d4617a4c3cebc550f8c39",
  PRIVACY_POLICY: "https://valoy.notion.site/b6d574435043417f9a6057b13fbfab34",
  ABOUT: "https://valoy.notion.site/35f69099ba754d319453f14df84fa625",
  HISTORY:
    "https://valoy.notion.site/6d6f328133e54ec8bd512cc555cf8714?v=d72a749e017f49ce873824fbb1f29c52&pvs=4",
  QNA: "https://valoy.notion.site/Q-A-c9297dd40d7f4654b0cc259d4ea77748",
  AD: "https://valoy.notion.site/8628e4ed19f740409b8ae831777d4db2",
  MAIL: "help@whattosay.kr",
  POLL: "https://docs.google.com/forms/d/12YGkod2VqcBCnT8Z2dCg5kRv2zrSewikmKntykRQk74",
  TOSS: "https://toss.me/%EB%AD%90%EB%9D%BC%EA%B3%A0%ED%95%A0%EA%B9%8C%ED%9B%84%EC%9B%90%ED%95%98%EA%B8%B0",
  PAYPAL: "https://paypal.me/studiovaloy?locale.x=ko_KR",
  BUYMEACOFFEE: "https://www.buymeacoffee.com/whttosay",
  INSTAGRAM: "https://www.instagram.com/whattosay.kr/"
}

export const ORDER_BY = {
  CREATED_AT: "createdat",
  COPIED: "pasted",
  REACTION: "totalReactionCount",
  VIEW: "viewCount",
  COMMENT: "commentCount",
  LAST_COPIED_AT: "lastcopiedat",
  LENGTH: "length"
}

export const ORDER = {
  ASC: "asc",
  DESC: "desc"
}

export const LENGTH = {
  SHORT: "short",
  LONG: "long"
}

export const COMMUNITY_REACTIONS = {
  LIKE: "like",
  WOW: "wow",
  SAD: "sad",
  HMM: "hmm"
}

export const COMMUNITY_CATEGORIES = [
  {
    id: "notice",
    shortDescription: "Notice",
    title: "공지사항",
    name: "공지사항",
    description: "업데이트나 이벤트 소식을 전해드릴게요.\n우리 자주 만나요!"
  },
  {
    id: "templates",
    shortDescription: "Q&A",
    title: "일잘러 모두 여기로",
    writeTitle: "이런 템플릿이 필요해요",
    writePlaceholder: `내용을 ${MIN_CONTENT_LENGTH}자 이상 입력해주세요\n* 템플릿을 사용하려는 대상과 상황 등을 구체적으로 설명해주시면 좋아요.`,
    commentPlaceholder: "이런 내용 받거나 보내본 적 있다면 공유 부탁드려요!",
    name: "템플릿 Q&A",
    description: "이럴 때 뭐라고 할까\n랜선 동료들과 의견을 나누어보세요."
  },
  {
    id: "etc",
    shortDescription: "Share anything",
    title: "우리 얘기 나눠요",
    name: "일상 Talk",
    writePlaceholder: `내용을 ${MIN_CONTENT_LENGTH}자 이상 입력해주세요`,
    description: "다양한 배경의 사람들과\nWork & Life 무엇이든 나누어보세요."
  }
]
