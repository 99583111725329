import React, { useState, useEffect, useMemo } from "react"
import styled from "styled-components"
import PageLayout from "../common/PageLayout"
import Hero from "./Hero"
import CheckBottom from "../common/CheckBottom"
import Empty from "../common/Empty"
import MasonryView from "../common/MasonryView"
import { ORDER, ORDER_BY } from "../common/constants"
import { ReactComponent as MoreArrow } from "../images/arrow-down.svg"
import { ReactComponent as Filter } from "../images/filter.svg"
import { ReactComponent as Reset } from "../images/refresh.svg"
import OrderByRadio from "../common/OrderByRadio"
import { isPageTop } from "../common/utils"
import { Configure, InstantSearch } from "react-instantsearch-hooks-web"
import SearchResult from "./SearchResult"
import MembershipBanner from "./MembershipBanner"
import Filters from "./Filters"

function TemplatesView(props) {
  const {
    options,
    searchClient,
    onSelectSituation,
    onSelectOrderBy,
    onFilterClick,
    isLoading,
    gettingMore,
    noMoreToLoad,
    isLoadingLogin,
    isLimited,
    membership,
    blurFrom,
    templates,
    loadMore,
    onSelectTag,
    onApplyFilter,
    onClickRequestTemplate,
    onClickWriteToUnlimitButton,
    onClickJoinMembership
  } = props

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800)
  const [showLimited, setShowLimited] = useState(false)
  const [showSearchResults, setShowSearchResults] = useState(false)

  const LIMITED_BUTTON_OFFSET =
    (isMobile ? 2200 : 100) * (showSearchResults ? 0.5 : blurFrom / 6)

  const isFiltered = useMemo(() => {
    return options.language || options.receiver || options.length
  }, [options])

  const selectedOrderBy = useMemo(() => {
    return {
      orderby: options.orderby || ORDER_BY.CREATED_AT,
      order: options.order || ORDER.DESC
    }
  }, [options])

  useEffect(() => {
    const resizeLisnener = () => {
      let isSmall = window.innerWidth <= 800
      if (isSmall !== isMobile) {
        setIsMobile(isSmall)
      }
    }
    window.addEventListener("resize", resizeLisnener)
    return () => {
      window.removeEventListener("resize", resizeLisnener)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setShowSearchResults(false)
  }, [options])

  useEffect(() => {
    const updateShowLimited = () => {
      if (isPageTop(LIMITED_BUTTON_OFFSET)) {
        if (showLimited) {
          setShowLimited(false)
        }
      } else {
        if (!showLimited) {
          setShowLimited(true)
        }
      }
    }
    if (isLimited) {
      window.addEventListener("scroll", updateShowLimited)
    }
    return () => {
      window.removeEventListener("scroll", updateShowLimited)
    }
  }, [isLimited, showLimited, LIMITED_BUTTON_OFFSET])

  const shouldShowEmptyView =
    !isLoading && templates.length === 0 && options.situation
  const emptyText = `‘${options.situation}’ 관련된 템플릿이 없네요!\n${
    isFiltered ? "필터를 해제하거나 " : ""
  }커뮤니티에 도움을 요청해보세요.`

  const filters = useMemo(() => {
    const filters = []
    if (options.situation) {
      filters.push(`situation:"${options.situation}"`)
    }
    if (options.receiver) {
      filters.push(`receiver:"${options.receiver}"`)
    }
    if (options.language) {
      if (options.language === "etc") {
        filters.push(`(NOT language:KOR AND NOT language:ENG)`)
      } else {
        filters.push(`language:"${options.language}"`)
      }
    }
    return filters.join(" AND ")
  }, [options])

  return (
    <PageLayout backgroundColor={isMobile ? "#F9F9F9" : undefined}>
      <InstantSearch
        searchClient={searchClient}
        indexName="whattosay_templates"
      >
        <Configure hitsPerPage={9} filters={filters} />
        {/* todo handle orderby on search */}
        <Hero
          currentSituation={options.situation}
          onSelectSituation={onSelectSituation}
          setShowSearchResults={setShowSearchResults}
        />
        {!isLoadingLogin && membership === "default" && (
          <MembershipBanner onClick={onClickJoinMembership} />
        )}
        {!showSearchResults && (
          <OptionsContainer className="space-y-2">
            {!isMobile && (
              <Filters
                filters={{
                  language: options.language,
                  receiver: options.receiver
                }}
                setFilters={onApplyFilter}
              />
            )}
            <RadioContainer>
              <OrderByRadio
                orderBys={[
                  ORDER_BY.COPIED,
                  ORDER_BY.LAST_COPIED_AT,
                  ORDER_BY.LENGTH,
                  ORDER_BY.CREATED_AT
                ]}
                currentOrderBy={selectedOrderBy.orderby}
                currentOrder={selectedOrderBy.order}
                onChange={onSelectOrderBy}
                disable={options.tag != null}
              />
              {isMobile ? (
                <FilterButton onClick={onFilterClick} isFiltered={isFiltered}>
                  <Filter stroke={isFiltered ? "#551fff" : "#333333"} />
                  <span>필터</span>
                </FilterButton>
              ) : (
                <ResetButton
                  className={
                    isFiltered
                      ? "cursor-pointer text-primary"
                      : "pointer-events-none text-[#c4c4c4]"
                  }
                  onClick={() => onApplyFilter({})}
                >
                  <Reset />
                  초기화
                </ResetButton>
              )}
            </RadioContainer>
          </OptionsContainer>
        )}
        {shouldShowEmptyView &&
          !showSearchResults &&
          options.orderby !== ORDER_BY.LAST_COPIED_AT && (
            <Empty
              text={emptyText}
              buttonText={"템플릿 요청하러 가기"}
              onClickButton={onClickRequestTemplate}
            />
          )}
        {!isLoading &&
          templates.length === 0 &&
          options.orderby === ORDER_BY.LAST_COPIED_AT && (
            <Empty text="복사된 템플릿이 없어요!" hideButton />
          )}
        {showSearchResults ? (
          <div className="sm:mt-[40px]">
            <SearchResult isLimited={isLimited} />
          </div>
        ) : (
          <div className="sm:mt-[12px]">
            <MasonryView
              isLoading={isLoading}
              templates={templates}
              onSelectTag={onSelectTag}
              blurFrom={isLimited ? blurFrom : undefined}
            />
          </div>
        )}
      </InstantSearch>
      {isLimited && showLimited && (
        <LimitedButton onClick={onClickWriteToUnlimitButton}>
          템플릿 등록하고 전체 보기
        </LimitedButton>
      )}
      {!showSearchResults &&
        !noMoreToLoad &&
        !isLoading &&
        !shouldShowEmptyView && (
          <>
            <LoadMore>
              {gettingMore ? (
                <svg
                  ariaHidden="true"
                  className="mx-auto mb-10 w-7 h-7 text-gray-200 animate-spin fill-primary"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                <MoreArrow
                  className="animate-bounce mx-auto"
                  width={32}
                  height={32}
                />
              )}
            </LoadMore>
            <CheckBottom onBottom={loadMore} showLoading={gettingMore} />
          </>
        )}
    </PageLayout>
  )
}

const LimitedButton = styled.div`
  position: fixed;
  display: inline-block;
  bottom: 9rem;
  width: 380px;
  left: calc(50% - 190px);
  text-align: center;
  background: #333333;
  color: white;
  padding: 19.5px 0;
  border-radius: 30px;
  font-size: 1.125rem;
  cursor: pointer;
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.16));
  z-index: 1;
  @media (max-width: 800px) {
    max-width: 85%;
    left: calc(50%);
    bottom: calc(50% - var(--top-padding));
    transform: translateX(-50%);
  }
`
const LoadMore = styled.div`
  text-align: center;
  margin-top: 18px;
  font-size: 1rem;
  color: #5551ff;
  p {
    margin-bottom: 4px;
  }
  @media (max-width: 800px) {
    font-size: 14px;
  }
`
const OptionsContainer = styled.div`
  position: relative;
  margin: 2rem auto 0;
  max-width: 1440px;
  padding: 0 24px;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    > div:first-child {
      width: 100%;
    }
  }
`
const RadioContainer = styled.div`
  display: flex;
  min-width: 280px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
    position: inherit;
    top: 0;
    padding: 0 1rem;
    height: 48px;
    background: #fafafa;
  }
`
const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 40px;
  gap: 4px;
  background: white;
  border: 1px solid ${({ isFiltered }) => (isFiltered ? "#593af0" : "#ebebeb")};
  border-radius: 50px;
  color: ${({ isFiltered }) => (isFiltered ? "#593af0" : "#333333")};
  cursor: pointer;
  @media (max-width: 800px) {
    font-size: 14px;
    width: 72px;
    height: 32px;
  }
`
const ResetButton = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
`

export default TemplatesView
