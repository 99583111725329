import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { ReactComponent as CopyHoverIcon } from "../images/copy-hover.svg"
import { ReactComponent as CopyDoneIcon } from "../images/thumbs.svg"
import { ReactComponent as HelpedIcon } from "../images/clapping.svg"
import { ReactComponent as LockIcon } from "../images/lock.svg"
import { ReactComponent as ReportIcon } from "../images/icons-circle-report.svg"
import { ReactComponent as DeleteIcon } from "../images/trash.svg"
import { ReactComponent as CopyIcon } from "../images/icons-paste.svg"
import warnIconSrc from "../images/icon-warn.svg"
import { db, fieldValue, logUserEvent } from "../common/firebase.utils"
import copy from "copy-to-clipboard"
import { Badge } from "../styles/style"
import MoreMenus from "../common/MoreMenus"
import { useDispatch } from "react-redux"
import { showUnlockPopup } from "../store/slices/layerSlice"
import useToast from "../hooks/useToast"
import { Highlight } from "react-instantsearch-hooks-web"
import { REPORT_HIDE_COUNT_TEMPLATE } from "../common/constants"

const blurContent =
  "안녕하세요 사용자님:)\n\n메일함에서 금방 찾을 수 있는 어떤 표현이라도 나누어 주시면 됩니다.\n뭐라고할까를 이용해주시고 더 오래 운영될 수 있도록 템플릿 공유에 참여해주셔서 감사드립니다.\n\n뭐라고할까 드림"

function Card({
  data,
  onSelectTag,
  isAdmin,
  isMine,
  isLimited,
  userId,
  blur,
  isSearchResult = false
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [tags, setTags] = useState([])
  const [copied, setCopied] = useState(false)
  const [copyHover, setCopyHover] = useState(false)
  const [copyCount, setCopyCount] = useState(data.pasted)
  const [isDeleted, setIsDeleted] = useState(data.deletedat)
  const [isTextScrollable, setIsTextScrollable] = useState(false)
  const dispatch = useDispatch()
  const textRef = useRef()
  const toast = useToast()
  let copiedTimeout = null

  const onTextScroll = () => {
    const textElem = textRef.current
    if (blur || !textElem) return setIsTextScrollable(false)
    if (textElem.scrollHeight > textElem.clientHeight) {
      const scrolledToBottom =
        textElem.scrollTop === textElem.scrollHeight - textElem.offsetHeight
      return setIsTextScrollable(!scrolledToBottom)
    }
    return setIsTextScrollable(false)
  }

  useEffect(() => {
    if (!blur && !isDeleted && data.id) {
      db.collection("tags")
        .where("templateid", "==", data.id)
        .get()
        .then(doc => {
          if (isLoading) {
            let data = null
            setTags([])
            if (doc.size > 0) {
              let added = []
              doc.docs.forEach(doc => {
                data = doc.data()
                added.push({
                  id: doc.id,
                  title: data.title
                })
              })
              setTags(t => t.concat(...added))
            }
            setIsLoading(false)
          }
        })
      onTextScroll()
    }

    return () => {
      setIsLoading(false)
      clearTimeout(copiedTimeout)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isDeleted) {
    return <Container className="deleted">삭제된 템플릿입니다.</Container>
  }

  if (
    data.reportCount != null &&
    data.reportCount >= REPORT_HIDE_COUNT_TEMPLATE
  ) {
    return (
      <Container className="deleted">
        신고 누적으로 가려진 템플릿입니다.
      </Container>
    )
  }

  const onClickCopy = e => {
    if (copyHover) setCopyHover(false)
    copy(data.content, { format: "text/plain" })
    setCopied(true)
    if ("vibrate" in window.navigator) {
      // vibration API supported
      window.navigator.vibrate(1000)
    }
    copiedTimeout = setTimeout(() => setCopied(false), 1000)
    db.collection("templates")
      .doc(data.id)
      .update({
        pasted: fieldValue.increment(1),
        lastcopiedat: fieldValue.serverTimestamp()
      })
      .then(() => {
        setCopyCount(copyCount + 1)
      })
    db.collection("statistics")
      .doc("actions")
      .update({
        copy: fieldValue.increment(1)
      })
    // 로그인 유저라면 복사 내역 저장.
    if (userId) {
      const _situationid = data.situationid
      db.collection("copies").add({
        userid: userId,
        templateid: data.id,
        situationid: _situationid,
        writerid: data.writerid,
        createdat: fieldValue.serverTimestamp()
      })
      db.collection("users")
        .doc(userId)
        .get()
        .then(doc => {
          const situationCopies = doc.data().situationCopyCount || {}
          situationCopies[_situationid] =
            (situationCopies[_situationid] || 0) + 1
          doc.ref.update({
            situationCopyCount: situationCopies
          })
        })
    }

    logUserEvent("copy", {
      value: data.situation
    })
  }
  const onCLickCopyLock = () => {
    logUserEvent("click_copy_lock")
    dispatch(showUnlockPopup())
  }

  const onTagClick = e => {
    logUserEvent("click_tag", { value: e.target.innerText })
    onSelectTag(e.target.innerText.substring(1))
  }

  const onReportClick = () => {
    if (
      !window.confirm(
        `해당 템플릿을 신고하시겠습니까?\n신고된 템플릿은 검토 후 삭제/수정 조치 됩니다.`
      )
    )
      return

    toast.showWithClose({
      iconSrc: warnIconSrc,
      text: "신고되었습니다. 관리자가 확인 후 조치하겠습니다."
    })

    db.collection("templates").doc(data.id).update({
      reported: true
    })

    if (!isAdmin) {
      db.collection("reports")
        .where("targetType", "==", "templates")
        .where("targetId", "==", data.id)
        .where("userId", "==", userId)
        .get()
        .then(snapshot => {
          if (snapshot.size === 0) {
            db.collection("reports").add({
              targetType: "templates",
              targetId: data.id,
              userId,
              createdat: fieldValue.serverTimestamp()
            })

            db.collection("users")
              .doc(data.writerid)
              .update({
                reported: fieldValue.increment(1)
              })
          }
        })
    }
  }

  const onDeleteClick = () => {
    if (!isMine) {
      window.alert("본인의 템플릿만 삭제할 수 있습니다.")
      return
    }

    if (
      !window.confirm(
        `정말 삭제하시겠습니까?\n이미 복사한 사용자들의 마이프로필에는 계속 보여집니다.`
      )
    ) {
      return
    }

    db.collection("templates")
      .doc(data.id)
      .update({
        deletedat: fieldValue.serverTimestamp()
      })
      .then(doc => {
        window.alert("삭제되었습니다.")
        setIsDeleted(true)
      })
      .catch(e => {
        window.alert(
          "죄송합니다. 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요."
        )
        console.error("error delete template: ", e)
      })
  }

  const isNew = () => {
    if (!data.createdat) return false
    const currentInSeconds = new Date().getTime() / 1000
    const pastDays = (currentInSeconds - data.createdat.seconds) / (3600 * 24)
    return pastDays <= 3
  }

  const getIcon = () => {
    if (copied) {
      return <CopyDoneIcon />
    } else if (copyHover) {
      return isLimited ? <LockIcon /> : <CopyHoverIcon />
    } else if (copyCount > 0) {
      return <HelpedIcon />
    }
    return <CopyIcon />
  }

  const buttonText = copied
    ? "잘 쓸게요"
    : copyHover
      ? "복사할래요"
      : copyCount > 0
        ? `${copyCount.toLocaleString()}명에게 도움 됐어요`
        : undefined

  return (
    <Container>
      {!isAdmin && isMine && !blur && <MyBadge>내가쓴글</MyBadge>}
      {isNew() && <NewBadge title="3일 이내 등록됐어요">신규템플릿</NewBadge>}
      <Title>
        <p>
          이렇게 <b>{data.situation}</b>
        </p>
      </Title>
      <Contents blur={blur}>
        <div
          id="text"
          onScroll={onTextScroll}
          ref={textRef}
          className="no-scrollbar"
        >
          {blur ? (
            blurContent
          ) : isSearchResult ? (
            <Highlight attribute="content" hit={data} />
          ) : (
            data.content
          )}
        </div>
        {isTextScrollable && <div className="scroll-blur" />}
        {!blur && tags.length > 0 && (
          <div id="tag">
            {tags.map((t, key) => (
              <span key={key} className="clickable" onClick={onTagClick}>
                #{t.title}
              </span>
            ))}
          </div>
        )}
        <Copy copyHover={copyHover} copied={copied}>
          <div
            onClick={blur ? null : isLimited ? onCLickCopyLock : onClickCopy}
            onMouseEnter={
              blur
                ? null
                : () => {
                    setCopyHover(true)
                  }
            }
            onMouseLeave={
              blur
                ? null
                : () => {
                    setCopyHover(false)
                  }
            }
          >
            <div>{getIcon()}</div>
            {buttonText && <span>{buttonText}</span>}
          </div>
        </Copy>
      </Contents>
      <div id="line" />
      <Bottom>
        <span id="nick">from. {data.writernick}</span>
        {!blur && userId != null && (
          <MoreMenus>
            {isMine ? (
              <DeleteIcon id="deleteIcon" onClick={onDeleteClick} />
            ) : (
              <ReportIcon id="reportIcon" onClick={onReportClick} />
            )}
          </MoreMenus>
        )}
      </Bottom>
    </Container>
  )
}

const NewBadge = styled(Badge)`
  background: #ffa800;
  margin-left: -24px;
`
const MyBadge = styled(Badge)`
  background: #333333;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  height: 3rem;

  #nick {
    font-size: 12px;
    color: #c5c5c5;
  }

  @media (max-width: 800px) {
    padding-left: 2rem;
  }
`

const Contents = styled.div`
  padding: 0 32px;
  position: relative;
  #text {
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.black};
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 60px;
    max-height: 180px;
    white-space: pre-wrap;
    filter: ${({ blur }) => (blur ? "blur(3px)" : "none")};
  }

  .scroll-blur {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 60px;
  }

  #tag {
    margin-top: 19px;
    font-size: 12px;
    color: #c5c5c5;

    span {
      display: inline-block;
      margin-right: 8px;
      transition: 0.1s;
      :hover {
        transform: translateY(-0.15em);
      }
    }
  }

  @media (max-width: 800px) {
    padding: 0 2rem;
  }
`
const Copy = styled.div`
  margin: 24px 0 32px;
  text-align: center;
  > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    height: 48px;
    padding: 0 16px;
    background: ${props =>
      props.copied ? "#5551FF" : props.copyHover ? "#e2e2ff" : "#FAFAFA"};
    border: 1px solid
      ${props =>
        props.copied ? "#5551FF" : props.copyHover ? "#d4d3ff" : "#EAEAEA"};
    border-radius: 40px;
    cursor: pointer;
    > div {
      display: inline-flex;
      align-items: center;
    }
    span {
      margin-left: 4px;
      font-size: 12px;
      color: ${props =>
        props.copied ? "#ffffff" : props.copyHover ? "#5551FF" : "#979797"};
    }
  }
`

const Title = styled.div`
  padding: 4px 32px 0;
  margin-bottom: 24px;
  color: ${props => props.theme.black};
  > p:first-of-type {
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;

    b {
      font-weight: 500;
    }
  }

  @media (max-width: 800px) {
    padding: 8px 2rem 0 2rem;
  }
`

const Container = styled.div`
  position: relative;
  padding-top: 32px;
  max-width: 100%;
  margin-bottom: 24px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  text-align: right;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  > * {
    text-align: left;
  }

  #line {
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.light_grey};
  }

  &.deleted {
    text-align: center;
    color: #c5c5c5;
    padding-bottom: 32px;
  }

  @media (max-width: 800px) {
    margin-bottom: 0.5rem;
    border: none;
    border-radius: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  }
`

export default Card
