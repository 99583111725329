import React from "react"
import styled from "styled-components"
import { useUserInfo } from "../hooks/useUserInfo"
import { useDispatch } from "react-redux"
import { showLoginPopup } from "../store/slices/layerSlice"

export default function Filters({ setFilters, filters = {} }) {
  const { language, receiver } = filters

  const { loggedIn } = useUserInfo()
  const dispatch = useDispatch()

  const onClickLanguage = e => {
    if (!loggedIn) {
      dispatch(showLoginPopup())
      return
    }

    const { id } = e.target
    setFilters({ ...filters, language: id })
  }

  const onClickReceiver = e => {
    if (!loggedIn) {
      dispatch(showLoginPopup())
      return
    }

    const { id } = e.target
    setFilters({ ...filters, receiver: id })
  }

  return (
    <div className="px-6 py-3 bg-[#f9f9fc] rounded border border-[#ebebeb] justify-between items-center flex flex-wrap gap-y-2">
      <div className="items-center gap-2 flex">
        <span className="text-[#121212] text-sm font-semibold">대화상대</span>
        <div onClick={onClickReceiver} className="flex flex-wrap gap-2">
          <Option isSelected={!receiver}>전체</Option>
          <Option id="colleague" isSelected={receiver === "colleague"}>
            직장, 동료
          </Option>
          <Option id="customer" isSelected={receiver === "customer"}>
            고객
          </Option>
          <Option id="friend" isSelected={receiver === "friend"}>
            친구
          </Option>
          <Option id="acq" isSelected={receiver === "acq"}>
            지인
          </Option>
          <Option id="parents" isSelected={receiver === "parents"}>
            부모님
          </Option>
          <Option id="prof" isSelected={receiver === "prof"}>
            교수님
          </Option>
          <Option id="etc" isSelected={receiver === "etc"}>
            기타
          </Option>
        </div>
      </div>
      <div className="items-center gap-2 flex">
        <span className="text-[#121212] text-sm font-semibold">언어</span>
        <div onClick={onClickLanguage} className="flex gap-2">
          <Option isSelected={!language}>전체</Option>
          <Option id="kor" isSelected={language === "kor"}>
            한글
          </Option>
          <Option id="eng" isSelected={language === "eng"}>
            영어
          </Option>
          <Option id="etc" isSelected={language === "etc"}>
            기타
          </Option>
        </div>
      </div>
    </div>
  )
}

const Option = styled.span`
  flex-shrink: 0;
  background: white;
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid ${({ isSelected }) => (isSelected ? "#593AF0" : " #ebebeb")};
  border-radius: 4px;
  line-height: 24px;
  color: ${({ isSelected }) => (isSelected ? "#593AF0" : " #333333")};
  cursor: pointer;
`
