import LanguageDetector from "languagedetect"
import copy from "copy-to-clipboard"
import moment from "moment"
import * as XLSX from "xlsx"
import { auth, db, fieldValue } from "../common/firebase.utils"
import { UNLIMITED_DATE_FORMAT } from "./constants"
import { twMerge } from "tailwind-merge"
import clsx from "clsx"

export const requestPay = userId => {
  if (!userId) {
    alert("로그인 후 이용해주세요.")
    return
  }
  if (typeof window !== "undefined") {
    const payElem = window
    const { AUTHNICE } = payElem
    AUTHNICE.requestPay({
      clientId: process.env.REACT_APP_NICEPAY_CLIENT_KEY,
      method: "cardAndEasyPay",
      orderId: userId + "_" + new Date().getTime(),
      amount: 5900,
      goodsName: "뭐라고할까 멤버십",
      mallUserId: userId,
      returnUrl:
        "https://us-central1-whattosay-48bb1.cloudfunctions.net/handlePayment",
      fnError: function (result) {
        if (result.msg) {
          alert(
            "고객용메시지 : " +
              result.msg +
              "\n개발자확인용 : " +
              result.errorMsg +
              ""
          )
        }
      }
    })
  }
}

export const isBlacklistedEmail = async email => {
  const snapshot = await db
    .collection("blacklists")
    .where("email", "==", email)
    .get()
  return snapshot.docs.length > 0
}

export const isPageTop = offset => {
  offset = offset != null ? offset : 400
  return window.pageYOffset <= offset
}

export const isInViewPort = (elem, offset = 0) => {
  if (!elem) return false
  const top = elem.getBoundingClientRect().top
  return top + offset >= 0 && top - offset <= window.innerHeight
}

const VALID_LANGS = ["kor", "eng", "jap", "chi", "deu"]
const DEFAULT_LANG = "kor"
const langDetector = new LanguageDetector()
langDetector.setLanguageType("iso3")
export const detectLanguage = text => {
  const result = langDetector.detect(text, 1)
  const lang = result?.length > 0 ? result[0][0] : DEFAULT_LANG
  return VALID_LANGS.includes(lang) ? lang : DEFAULT_LANG
}

export const hasRestrictedWords = async content => {
  const restricteds = await getRestrictedWords()
  let wordIndex
  const has = restricteds.some((restricted, index) => {
    wordIndex = index
    return content.includes(restricted)
  })
  return has ? restricteds[wordIndex] : false
}
let restrictedWords
const getRestrictedWords = async () => {
  if (!restrictedWords) {
    restrictedWords = await fetch("/restrictedWords.xlsx")
      .then(res => res.arrayBuffer())
      .then(buffer => {
        const data = new Uint8Array(buffer)
        const arr = new Array()
        for (let i = 0; i != data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join("")
        const xlsx = XLSX.read(bstr, { type: "binary" })
        const wsname = xlsx.SheetNames[0]
        const ws = xlsx.Sheets[wsname]
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 })
        return dataParse
      })
      .then(arrayOfArray => {
        return arrayOfArray.reduce((prev, current) => {
          prev.push(current[0])
          return prev
        }, [])
      })
  }
  return restrictedWords
}

export const isLimited = unlimitedUntil => {
  if (!unlimitedUntil) return true
  const untilMoment = moment(unlimitedUntil, UNLIMITED_DATE_FORMAT)
  return moment().isAfter(untilMoment)
}

export const getPastTimeText = timestamp => {
  if (!timestamp) return ""
  const currentMoment = moment()
  const targetMoment = moment(timestamp.toDate())

  const diffDays = currentMoment.diff(targetMoment, "d")
  if (diffDays < 1) {
    const diffHours = currentMoment.diff(targetMoment, "H")
    if (diffHours < 1) {
      const diffMins = currentMoment.diff(targetMoment, "m")
      return diffMins + "분 전"
    }
    return diffHours + "시간 전"
  }
  if (diffDays <= 30) {
    return diffDays + "일 전"
  }
  const diffMonths = currentMoment.diff(targetMoment, "M")
  return diffMonths + "달 전"
}

export const getHighestValues = (obj, num) => {
  const resultObj = {}
  const objSize = Object.keys(obj).length
  if (num > objSize) {
    num = objSize
  }
  Object.keys(obj)
    .sort((a, b) => obj[b] - obj[a])
    .forEach((key, ind) => {
      if (ind < num) {
        resultObj[key] = obj[key]
      }
    })
  return resultObj
}

export const addViewCount = (collection, id) => {
  const viewCountedAt = JSON.parse(localStorage.getItem(`v${collection}`))
  const isPassed5Minutes =
    !viewCountedAt ||
    viewCountedAt.id !== id ||
    moment().valueOf() - viewCountedAt.at > 5 * 60 * 1000
  if (isPassed5Minutes) {
    db.collection(collection)
      .doc(id)
      .update("viewCount", fieldValue.increment(1))
      .then(() => {
        localStorage.setItem(
          `v${collection}`,
          JSON.stringify({
            id,
            at: moment().valueOf()
          })
        )
      })
  }
}

const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}
export const debounced = (func, wait) => debounce(func, wait)

export const share = () => {
  copy("https://whattosay.kr", { format: "text/plain" })
  alert("클립보드에 URL이 복사되었습니다☺️")
}

export const logout = () => {
  auth.signOut()
  alert("로그아웃 되었습니다.")
}

export const cn = (...classes) => {
  return twMerge(clsx(classes))
}
