import React from "react"
import styled from "styled-components"
import { ReactComponent as CloseButton } from "../images/close.svg"
import { ReactComponent as MailsImg } from "../images/mails.svg"
import AuthManager from "../common/AuthManager"
import GoogleLoginButton from "../common/GoogleLoginButton"
import { URL } from "../common/constants"
import NaverLoginButton from "../common/NaverLoginButton"

function LoginPopup(props) {
  const {
    onLogin,
    onCloseClick,
    message = `반가워요! 뭐라고할까를 이용하려면\n간편하게 로그인하세요`
  } = props

  return (
    <Background>
      <AuthManager onLogin={onLogin} />
      <Container>
        <CloseButton id="close_button" onClick={onCloseClick} fill="white" />
        <MailsImg id="mails" />
        <p>{message}</p>
        <div className="buttons">
          <GoogleLoginButton />
          <NaverLoginButton />
        </div>
        <p>
          로그인은{" "}
          <a
            href={URL.PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
          >
            개인정보 처리방침
          </a>{" "}
          및{" "}
          <a
            href={URL.TERMS_OF_SERVICE}
            target="_blank"
            rel="noopener noreferrer"
          >
            서비스 이용약관
          </a>
          에<br />
          동의하는 것을 의미하며, 중요 알림 제공을 위해 이메일을 수집합니다.
        </p>
      </Container>
    </Background>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33.75rem;
  height: 25rem;
  border-radius: 10px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  background: linear-gradient(
      0deg,
      rgba(255, 168, 0, 0.16) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    #5551ff;
  text-align: center;
  color: #ffffff;
  cursor: default;

  .mobile {
    display: none;
  }

  #close_button {
    position: absolute;
    top: 31.55px;
    right: 31.58px;
    opacity: 0.8;
    cursor: pointer;
    :hover {
      opacity: 1;
    }
  }

  #mails {
    margin: 0 auto 25.72px;
  }

  .buttons {
    > :first-child {
      margin-bottom: 16px;
    }
  }

  p:nth-of-type(1) {
    margin-bottom: 2.5rem;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    white-space: pre-wrap;
  }

  p:nth-of-type(2) {
    margin-top: 24px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #9391ef;
    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    border-radius: 0;

    .mobile {
      display: inherit;
    }

    p:nth-of-type(1) {
      margin-bottom: 1rem;
    }
    p:nth-of-type(2) {
      margin-top: 1rem;
    }
  }
`

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
`

export default LoginPopup
