import React, { useState } from "react"
import styled from "styled-components"
import { ReactComponent as CloseImg } from "../images/close-gray.svg"
import { cn } from "../common/utils"
import { db } from "../common/firebase.utils"
import { hasRestrictedWords } from "../common/utils"
import { useDispatch } from "react-redux"
import { update } from "../store/slices/userSlice"

export default function NicknamePopup({
  userId,
  currentNickname,
  onCloseClick
}) {
  const [nickname, setNickname] = useState("")
  const [isAvailable, setIsAvailable] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const onChangeNickname = e => {
    let value = e.target.value
    if (value.length > 11) {
      value = value.slice(0, 11)
    }
    setNickname(value)
    setIsAvailable(null)
  }

  const checkNickname = async () => {
    const _nickname = nickname.trim()
    if (_nickname.length === 0) {
      setIsAvailable(null)
      return
    }

    if (_nickname === currentNickname) {
      setIsAvailable(true)
      return
    }

    setIsLoading(true)

    const includedBadWord = await hasRestrictedWords(_nickname)
    if (includedBadWord) {
      setIsAvailable(false)
      setIsLoading(false)
      return
    }

    db.collection("users")
      .where("nickname", "==", _nickname)
      .get()
      .then(snapshot => {
        setIsAvailable(snapshot.empty)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }

  const onClickChange = async () => {
    const _nickname = nickname.trim()
    if (!isAvailable || !_nickname || isLoading) {
      return
    }

    if (_nickname === currentNickname) {
      onCloseClick()
      return
    }

    setIsLoading(true)

    db.collection("users")
      .doc(userId)
      .update({
        nickname: _nickname
      })
      .then(() => {
        dispatch(update({ nickname: _nickname }))
        setIsLoading(false)
        onCloseClick()
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }

  return (
    <Background>
      <Container>
        <header className="relative h-[53px] bg-[#333333] text-white">
          <span className="left-6 leading-[13px] text-lg font-bold top-6 absolute">
            닉네임 변경
          </span>
          <button
            onClick={onCloseClick}
            className="absolute right-[6.5px] top-2"
          >
            <CloseImg />
          </button>
        </header>
        <div className="py-6 px-10">
          <div className="flex items-center gap-2 flex-col md:flex-row">
            <input
              value={nickname}
              onChange={onChangeNickname}
              maxLength={11}
              type="text"
              autoFocus
              placeholder="닉네임을 입력해주세요."
              className="w-full text-sm placeholder:text-[#D4D4D4] h-[44px] rounded-lg border border-[#EBEBEB] px-4 focus:outline-none"
            />
            <button
              className="text-center w-full md:w-auto shrink-0 border-primary border text-primary leading-[42px] text-sm font-medium px-6 rounded-lg"
              disabled={nickname.length === 0 || isLoading}
              onClick={checkNickname}
            >
              중복확인
            </button>
          </div>
          <p
            className={cn("text-sm text-[#333333]", {
              "mt-2": isAvailable != null,
              "text-[#00DE24]": isAvailable === true,
              "text-[#EA4335]": isAvailable === false
            })}
          >
            {isAvailable == null
              ? ""
              : isAvailable
                ? "사용 가능한 닉네임입니다."
                : "사용할 수 없는 닉네임입니다. 다른 닉네임을 입력해주세요."}
          </p>
        </div>
        <div className="py-4 px-10 bg-[#F4F5F9]">
          <button
            className="w-full disabled:opacity-50 rounded-full bg-primary text-white text-sm leading-[48px]"
            disabled={!isAvailable || isLoading}
            onClick={onClickChange}
          >
            {isLoading ? "변경중..." : "변경하기"}
          </button>
        </div>
      </Container>
    </Background>
  )
}

const Container = styled.div`
  width: 562px;
  max-width: 90%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
`

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
`
