import React, { useState } from "react"
import styled from "styled-components"

import { LENGTH } from "./constants"
import { useDispatch } from "react-redux"
import { showLoginPopup } from "../store/slices/layerSlice"
import { useUserInfo } from "../hooks/useUserInfo"
import { ReactComponent as CloseButton } from "../images/close-gray.svg"
import { ReactComponent as Reset } from "../images/refresh.svg"

function FilterPopup({ currentFilters, membership, onApply }) {
  const [filters, setFilters] = useState({ ...currentFilters })
  const { language, receiver, length } = filters
  const { loggedIn } = useUserInfo()
  const dispatch = useDispatch()

  const [isLengthChecked, setIsLengthChecked] = useState({
    [LENGTH.SHORT]: !length || length === LENGTH.SHORT,
    [LENGTH.LONG]: !length || length === LENGTH.LONG
  })

  const onReset = () => {
    setFilters({})
    setIsLengthChecked({
      [LENGTH.SHORT]: !length || length === LENGTH.SHORT,
      [LENGTH.LONG]: !length || length === LENGTH.LONG
    })
  }

  const onClickApply = () => {
    if (!loggedIn) {
      dispatch(showLoginPopup())
      return
    }

    if (
      currentFilters.language === language &&
      currentFilters.receiver === receiver &&
      currentFilters.length === length
    ) {
      onApply()
    } else {
      onApply(filters)
    }
  }

  const onClickLanguage = e => {
    const { id } = e.target
    setFilters({ ...filters, language: id })
  }

  const onClickReceiver = e => {
    const { id } = e.target
    setFilters({ ...filters, receiver: id })
  }

  // const onLengthChange = e => {
  //   const { id, checked } = e.target
  //   const otherId = id === LENGTH.SHORT ? LENGTH.LONG : LENGTH.SHORT
  //   if (checked) {
  //     setIsLengthChecked({ ...isLengthChecked, [id]: true })
  //     setFilters({
  //       ...filters,
  //       length: isLengthChecked[otherId] ? undefined : id
  //     })
  //   } else {
  //     if (!isLengthChecked[otherId]) {
  //       return
  //     }
  //     setIsLengthChecked({ ...isLengthChecked, [id]: false })
  //     setFilters({ ...filters, length: otherId })
  //   }
  // }

  return (
    <Background>
      <Container>
        <h1>필터</h1>
        <CloseButton
          onClick={() => onApply()}
          className="cursor-pointer absolute right-1.5 top-1"
        />
        <section>
          <h2>언어</h2>
          <div onClick={onClickLanguage}>
            <Option isSelected={!language}>전체</Option>
            <Option id="kor" isSelected={language === "kor"}>
              한글
            </Option>
            <Option id="eng" isSelected={language === "eng"}>
              영어
            </Option>
            <Option id="etc" isSelected={language === "etc"}>
              기타
            </Option>
          </div>
        </section>
        <section>
          <h2>대화상대</h2>
          <div onClick={onClickReceiver}>
            <Option isSelected={!receiver}>전체</Option>
            <Option id="colleague" isSelected={receiver === "colleague"}>
              직장, 동료
            </Option>
            <Option id="customer" isSelected={receiver === "customer"}>
              고객
            </Option>
            <Option id="friend" isSelected={receiver === "friend"}>
              친구
            </Option>
            <Option id="acq" isSelected={receiver === "acq"}>
              지인
            </Option>
            <Option id="parents" isSelected={receiver === "parents"}>
              부모님
            </Option>
            <Option id="prof" isSelected={receiver === "prof"}>
              교수님
            </Option>
            <Option id="etc" isSelected={receiver === "etc"}>
              기타
            </Option>
          </div>
        </section>
        {/* <section>
          <h2 style={{ marginBottom: "8px" }}>템플릿 길이</h2>
          <div>
            <label>
              <input
                type="checkbox"
                id={LENGTH.SHORT}
                checked={isLengthChecked[LENGTH.SHORT]}
                onChange={onLengthChange}
              />
              단문
            </label>
            <label>
              <input
                type="checkbox"
                id={LENGTH.LONG}
                checked={isLengthChecked[LENGTH.LONG]}
                onChange={onLengthChange}
              />
              장문
            </label>
          </div>
        </section> */}
        <BottomSection>
          <span onClick={onReset}>
            <Reset />
            초기화
          </span>
          <div onClick={onClickApply}>필터 적용</div>
        </BottomSection>
      </Container>
    </Background>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30rem;
  max-width: 100%;
  min-height: 21rem;
  border-radius: 8px;
  box-shadow: 0px 2.25556px 27.0667px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow: hidden;

  h1 {
    padding: 16px 24px 24px;
    background: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
  }

  section {
    padding: 0 24px 16px;
    h2 {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #767676;
    }
    div {
      display: inline-block;
    }
    label {
      display: flex;
      height: 35px;
      align-items: center;
      input {
        margin-right: 8px;
        accent-color: #593af0;
      }
    }
  }

  @media (max-width: 800px) {
    border-radius: 0;
  }
`
const Option = styled.span`
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 8px 16px;
  border: 1px solid ${({ isSelected }) => (isSelected ? "#593AF0" : " #ebebeb")};
  border-radius: 4px;
  line-height: 24px;
  color: ${({ isSelected }) => (isSelected ? "#593AF0" : " #333333")};
  cursor: pointer;
`
const BottomSection = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  margin-top: -4px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ebebeb;
  background-color: #f9f9fc;
  span {
    display: flex;
    align-items: center;
    padding: 8px;
    color: #c4c4c4;
    cursor: pointer;
    gap: 8px;
  }
  div {
    padding: 12.5px 32px;
    background: #593af0;
    border-radius: 4px;
    line-height: 19px;
    color: #ffffff;
    cursor: pointer;
  }
`
const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 50;
`

export default FilterPopup
